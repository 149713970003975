/* Spacers utility classes */

// loop for each spacer
@each $key, $value in $spacers {
  .p-#{$key} {
    padding: $value !important;
  }

  .pt-#{$key} {
    padding-top: $value !important;
  }

  .pr-#{$key} {
    padding-right: $value !important;
  }

  .pb-#{$key} {
    padding-bottom: $value !important;
  }

  .pl-#{$key} {
    padding-left: $value !important;
  }

  .px-#{$key} {
    padding-left: $value !important;
    padding-right: $value !important;
  }

  .py-#{$key} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }

  .m-#{$key} {
    margin: $value !important;
  }

  .mt-#{$key} {
    margin-top: $value !important;
  }

  .mr-#{$key} {
    margin-right: $value !important;
  }

  .mb-#{$key} {
    margin-bottom: $value !important;
  }

  .ml-#{$key} {
    margin-left: $value !important;
  }

  .mx-#{$key} {
    margin-left: $value !important;
    margin-right: $value !important;
  }

  .my-#{$key} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  // loop for each breakpoint
  @each $bp, $bpv in $breakpoints {
    @media (min-width: #{$bpv}) {
      .#{$bp}\:p-#{$key} {
        padding: $value !important;
      }

      .#{$bp}\:pt-#{$key} {
        padding-top: $value !important;
      }

      .#{$bp}\:pr-#{$key} {
        padding-right: $value !important;
      }

      .#{$bp}\:pb-#{$key} {
        padding-bottom: $value !important;
      }

      .#{$bp}\:pl-#{$key} {
        padding-left: $value !important;
      }

      .#{$bp}\:px-#{$key} {
        padding-left: $value !important;
        padding-right: $value !important;
      }

      .#{$bp}\:py-#{$key} {
        padding-top: $value !important;
        padding-bottom: $value !important;
      }

      .#{$bp}\:m-#{$key} {
        margin: $value !important;
      }

      .#{$bp}\:mt-#{$key} {
        margin-top: $value !important;
      }

      .#{$bp}\:mr-#{$key} {
        margin-right: $value !important;
      }

      .#{$bp}\:mb-#{$key} {
        margin-bottom: $value !important;
      }

      .#{$bp}\:ml-#{$key} {
        margin-left: $value !important;
      }

      .#{$bp}\:mx-#{$key} {
        margin-left: $value !important;
        margin-right: $value !important;
      }

      .#{$bp}\:my-#{$key} {
        margin-top: $value !important;
        margin-bottom: $value !important;
      }
    }
  }
}
