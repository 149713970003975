/* ----------------------------- */
/* ==Reset (forms)               */
/* ----------------------------- */

/*
 * Remove the tapping delay on clickable elements in all browsers .
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
 * 1. Change the inconsistent appearance in all browsers.
 * 2. Add typography inheritance in all browsers.
 */

button,
input,
select,
textarea {
  margin: 0;
  background-color: transparent; /* 1 */
  color: inherit; /* 1 */
  font-family: inherit; /* 2 */
  font-size: inherit; /* 2 */
  line-height: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  vertical-align: middle;
}

/*
 * Basic User Interface reset
 */

button,
input:not([type='radio']):not([type='checkbox']),
select,
textarea {
  border: 0;
}

/*
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre,
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

pre {
  tab-size: 2;
  white-space: pre-wrap;
  line-height: normal;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

/*
 * Show overflow in IE/Edge
 */

button,
input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox
 */

button,
select {
  text-transform: none;
}

/*
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

form,
fieldset {
  border: none;
}

fieldset {
  margin: 0;
  padding: $spacer-small;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0 $spacer-tiny;
  border: 0;
  color: inherit;
  white-space: normal;
}

label {
  display: inline-block;
  cursor: pointer;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  white-space: pre-wrap;
}

progress {
  display: inline-block;
  width: 100%;
  vertical-align: baseline;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

/* ----------------------------- */
/* Form oddities                 */
/* ----------------------------- */

/*
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/*
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/*
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/*
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}
