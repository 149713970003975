.QMuiTextarea {
  width: 100%;
  max-width: 100%;

  textarea {
    border: 1px solid var(--color-alpha);
    border-radius: 2rem;
    max-width: 100%;
    min-height: 4rem;
    padding: 0.6rem 1rem;
    font-family: inherit;
  }

  &.isError {
    label {
      color: $color-gamma;
    }

    textarea {
      border-color: $color-gamma;
    }
  }
}
