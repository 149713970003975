.CustomerService {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .CustomerService {
    flex-direction: column;
  }
}
