/* ----------------------------  */
/* ==Layout classes              */
/* ----------------------------- */

/* Global container */
.layout-maxed {
  display: grid;

  @each $key, $value in $breakpoints {
    @media (min-width: #{$value}) {
      grid-template-columns:
        minmax($spacer-small, 1fr)
        minmax(auto, $value)
        minmax($spacer-small, 1fr);
    }
  }
}

/* Center all children */
.layout-maxed > * {
  grid-column: 2;
}

/* Hero box  */
.layout-hero {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
}

.layout-hero > * {
  grid-column: 2;
}

/* Hero image */
.layout-hero-img {
  grid-column: 1 / -1;
  justify-self: center;
  max-width: 100%;
}
