.Snackbar {
  position: fixed;
  z-index: 50000;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Snackbar-item {
  padding: 0.8rem 1.5rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  background: white;
  border: 1px solid var(--color-alpha);
  display: flex;
  align-items: center;
  font-size: 1.7rem;

  svg {
    font-size: 2.3rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }

  &.isSuccess {
    background-color: lighten(#4caf50, 50);
    border-color: #4caf50;
    color: #4caf50;
  }

  &.isError {
    background-color: lighten($color-gamma, 40);
    border-color: $color-gamma;
    color: $color-gamma;
  }

  &-appear {
    animation-name: bounce;
  }

  &-appearActive {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
