.Documents {
  margin: auto;
  max-width: 1100px;
  @include respond-to('small') {
    padding-bottom: 5rem;
  }
}

.Documents-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Documents-sectionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
