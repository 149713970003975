.Login {
  display: flex;
  height: 100vh;
  background-position: top 0 left 37%;
  background-size: cover;
}

.Login-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 10px;

  img {
    max-width: 18rem;
  }
}

.Login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--login-bg);
  flex: 1;
  min-width: 55rem;
  position: relative;
}

.Login-leftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  max-width: 450px;
  position: relative;
  z-index: 20;
}

.Login-wave,
.Login-waveShadow {
  display: block;
  width: 100vh;
  position: absolute;
  transform-origin: center;
  transform: translateX(55%) rotate(90deg);
  right: 1rem;
  z-index: 6;
  color: var(--login-bg);
  max-width: none;
}

.Login-waveShadow {
  transform: translateX(56%) rotate(90deg);
  color: rgba(170, 170, 170, 0.8);
  z-index: 5;
}

.Login-right {
  display: flex;
  width: 60%;
  flex: 0 1 60%;
  background-size: cover;
  background-repeat: no-repeat;
}

.Login-title {
  font-size: 5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  color: white;
}

.Login-subtitle {
  display: block;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: white;
  line-height: 1.2;
}

%LoginLink {
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
}

.Login-forgotMdpLink {
  @extend %LoginLink;
  display: block;
  margin-bottom: 20px;
  text-align: end;
}

.Login-noAccount {
  @extend %LoginLink;
  line-height: 1.3;

  span {
    display: block;
    text-align: center;
  }
}

.Login-form {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;

  .MuiInputAdornment-root {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.Login-validationMsg {
  text-align: center;
  padding: 0.5rem;
  background-color: white;
  border-radius: 40px;
}

.Login-submitBtn {
  margin-top: 1rem;
}

@media (max-width: 1000px) {
  .Login-right {
    background-position: 25% 0;
  }
}

@media (max-width: $medium) {
  .Login {
    //font-size: .8rem;
  }

  .Login-header {
    display: flex;
    justify-content: flex-end;

    img {
      max-height: 5rem;
    }
  }

  .Login-container {
    flex-wrap: wrap;
  }

  .Login-form {
    padding: 0 30px;
  }

  .Login-left {
    min-width: auto;
    background-color: transparentize(#0880aa, 0.3);
  }

  .Login-title {
    font-size: 3rem;
  }

  .Login-subtitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .Login-right {
    display: none;
  }

  .Login-leftContainer {
    margin: 0 20px;
  }

  .Login-wave,
  .Login-waveShadow {
    display: none;
  }

  .Login-forgotMdpLink {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

@media (max-width: $large) {
  .Login-right {
    width: 70%;
  }
}

@include respond-to('small') {
  .Login-title {
    margin-bottom: 10px;
  }

  .Login-form {
    margin-top: 30px;
    padding: 0;
  }
}
