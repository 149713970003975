.QSelect {
  position: relative;
  min-height: 2rem;
}

.QSelect-container {
  display: flex;
  align-items: center;
  box-shadow: none;
  font-weight: normal;
  position: relative;

  & > div {
    width: 100%;
  }
}

.QSelect-icon {
  width: 18px;
  vertical-align: bottom;
  margin-left: 20px;
  margin-right: 15px;
}

// react-select override
.QSelect__control {
  border-radius: 20px !important;
  box-shadow: none !important;

  &--is-disabled {
    background-color: white !important;

    .QSelect__indicators {
      display: none;
    }
  }
}

.QSelect__single-value--is-disabled {
  color: black !important;
  max-width: calc(100% - 2.5rem) !important;
}

.QSelect__value-container {
  padding-left: 12px !important;
  font-size: 1.4rem;
}

.QSelect__option {
  &--is-focused {
    background-color: transparentize($color-alpha, 0.9) !important;
  }

  &--is-selected {
    background-color: $color-alpha !important;
  }
}

.QSelect__menu {
  border-radius: 10px !important;
  overflow: hidden !important;
  font-size: 1.4rem;
  z-index: 2000 !important;
}

.QSelect__menu-list {
  padding: 0 !important;
}

.QSelect__multi-value {
  z-index: 3;
  background-color: lighten($color-alpha, 10) !important;
  color: white;

  .QSelect__multi-value__label {
    color: white;
    font-size: 1.2rem;
  }
}

.QSelect {
  .QSelect__option {
    display: none !important;
  }
}
