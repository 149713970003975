.CheckFolder {
  background: linear-gradient(to right, #0378a0, #0a90be);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.CheckFolder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffffe3;
  padding: 2rem;
  width: 60rem;
  max-width: 100%;
  @extend .card;

  h1 {
    margin-bottom: 0;
  }

  h2 {
    font-style: italic;
  }
}

.CheckFolder-logo {
  max-width: 20rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.CheckFolder-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
}

.CheckFolder-successMsg {
  color: var(--color-alpha);
  font-weight: bold;
}
