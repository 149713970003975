.Menu-item {
  font-weight: 600;
  background: transparent;
  height: 4.2rem;
  border-bottom: 1px solid var(--color-alpha-light);
  font-size: 1.5rem;

  &:hover {
    background: white;
    border-left-color: white;
  }

  &.isActive {
    background: white;
    outline: none;

    & > a,
    & > button {
      border-left-color: $color-beta;
    }
  }
}

.Menu-itemIcon {
  display: block;
  width: 3rem;
  height: 3rem;
  text-align: center;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Menu-itemCounter {
  display: block;
  margin-left: auto;
  background: $color-beta;
  color: white;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  font-size: 70%;
}

.Menu-link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  background: transparent;
  border: 0;
  border-left: 0.5rem solid transparent;
  font: inherit;
  font-weight: bold;
  color: var(--color-alpha-light);
  cursor: pointer;
  outline: none;
}

.MainMenu-item {
  .Menu-itemIcon {
    width: 5rem;
    height: 5rem;
    margin-right: 0;
  }
}
