.QColorPicker {
  position: relative;
  flex: 0 0 auto;
}

.QColorPicker-swatch {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
}

.QColorPicker-cover {
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.QColorPicker-popover {
  position: absolute;
  z-index: 50;
}
