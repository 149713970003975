.NoteItem {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-top: 1px solid $color-gray-2;
}

.NoteItem-name {
  font-family: inherit;
  border: 0;
  padding: 0;
  font-weight: bold;
  width: 100%;
  font-size: 1.4rem;
  margin-left: 1rem;
}
