.MuiMenu-list {
  padding: 0;
}

.MuiMenuItem-root {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-alpha);
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-alpha);
  }
}
