.QualyTeam-main {
  display: flex;
  justify-content: space-between;
  margin: auto;
  @include respond-to('small') {
    padding-bottom: 5rem;
  }
}

.QualyTeam-team {
  width: 49%;
  order: 1;
  @include respond-to('small') {
    order: 0;
  }
  .QualyTeam-lawyer {
    margin-bottom: 2rem;
  }

  h3 {
    color: var(--color-alpha);
    font-size: 2rem;
  }
}

.QualyTeam-infos {
  width: 49%;
  order: 0;
  @include respond-to('small') {
    order: 1;
  }
  & > div {
    @extend .card;
    padding: 0;
    overflow: hidden;
  }
}

.QualyTeam-infosTitle {
  color: white;
  background-color: var(--color-alpha);
  padding: 2rem;
  margin: 0;
}

.QualyTeam-infosSubtitle {
  color: var(--color-alpha);
  font-size: 2rem;
}

.QualyTeam-lawyer {
  @extend .card;
  padding: 2rem 2rem 2rem 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    height: 100%;
    width: 16%;
    min-width: 16%;
    background-color: var(--color-alpha);
  }

  h3 {
    margin: 0;
  }
}

.QualyTeam-lawyerHead {
  position: relative;
  width: 30%;
  min-width: 30%;
  margin: 0 1rem;

  span {
    position: absolute;
    text-align: center;
    background: white;
    color: var(--color-alpha);
    padding: 0.5rem;
    border-radius: 20px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    font-weight: bold;
    font-size: 1.3rem;
  }
}

.QualyTeam-lawyerHead-pic {
  position: relative;
  border-radius: 50%;
  width: 100%;
  overflow: hidden;
  border: 3px solid white;
  background: $color-gray-2;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.QualyTeam-lawyerForm {
  width: 40%;
}

.QualyTeam-presentationEdit {
  font-size: 1.5rem;
  border: 1px solid #ccc;
  width: 100%;
}

.QualyTeam-presentationEditBtn {
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 600px) {
  .QualyTeam-lawyer {
    padding-bottom: 3rem;
  }

  .QualyTeam-lawyerHead {
    span {
      bottom: 0rem;
      width: 100%;
      border: 1px solid $color-gray-2;
      transform: translate(-50%, 50%);
    }
  }
}

@media screen and (max-width: 1000px) {
  .QualyTeam-main {
    flex-direction: column;
  }

  .QualyTeam-team {
    width: 100%;
    order: 1;
  }

  .QualyTeam-infos {
    width: 100%;
    margin-bottom: 4rem;
  }
}
