$color-btn-alpha: linear-gradient(to right, #0378a0, #0a90be);

.Synthesis {
  margin: 0 auto 6rem auto;
}

.Synthesis-container {
  display: flex;
  justify-content: space-between;
}

.Synthesis-infos {
  @extend .card;
  position: relative;
  padding: 3.5rem 1rem 0.5rem 1rem;
  width: 48%;

  dl {
    div {
      display: flex;
    }

    dt {
      width: 18rem;
    }

    dd {
      color: var(--color-alpha);
      width: calc(100% - 12rem);
    }
  }

  h2 {
    position: absolute;
    top: -2rem;
    left: 0;
    width: 28rem;
    max-width: 100%;
    background: var(--color-btn-alpha);
    color: white;
    border-radius: 20px 20px 20px 0;
    padding: 0.5rem 1.5rem;
  }
}

.FolderSynthesis {
  @extend .card;
  position: relative;
  padding: 3.5rem 1rem 0.5rem 1rem;
  //margin: 0 2rem;
}

.FolderSynthesis-head {
  position: absolute;
  top: -2rem;
  left: 0;
  max-width: 100%;
  background: var(--color-btn-alpha);
  color: white;
  border-radius: 20px 20px 20px 0;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  width: 100%;
  height: 5.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }

  .QMuiButton.isIcon svg {
    color: white;
  }
}

.FolderSynthesis-item {
  h4 {
    color: var(--color-alpha);
    margin: 0;
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-gray-2;
  }
}

.FolderSynthesis-noResults {
  display: block;
  font-weight: bold;
  text-align: center;
  color: var(--color-alpha);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.FolderSynthesis-titleInput {
  color: var(--color-alpha);
  font-size: 1.4rem;
  font-weight: bold;
  flex-grow: 1;
}

.FolderSynthesis-contentInput {
  font-size: 1.5rem;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .Synthesis-container {
    flex-direction: column;
  }

  .Synthesis-infos {
    width: 100%;

    dl div {
      flex-wrap: wrap;
    }

    &:nth-of-type(2) {
      margin-top: 5rem;
    }
  }
}

@include respond-to('small') {
  .Synthesis {
    padding-bottom: 10rem;
  }
}
