$heightInput: 38px;

.input {
  border: 1px solid var(--color-alpha);
  border-radius: $borderRadius;
  background: white;
  padding: 3px 10px;
  font-size: 1.4rem;
  font-family: inherit;

  input {
    padding-left: 0.8rem;
  }

  &:after {
    display: none;
  }

  &.isError {
    border-color: $color-gamma;
  }

  &.loginVariant {
    background-color: var(--color-alpha);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    color: white;
    font-size: 1.6rem;
  }

  &.firstLoginVariant {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    color: var(--color-alpha);
    font-size: 1.6rem;
  }

  &.Mui-disabled {
    border-color: rgba(0, 0, 0, 0.26);
  }
}

.input-label {
  position: absolute;
  top: 0;
  margin-top: 7px;
  left: 18px;
  color: var(--color-alpha-light);
  z-index: 2;
  transition: transform 0.2s, background-color 0.3s;
  user-select: none;
  pointer-events: none;
  font-size: 1.3rem;
  padding: 0.2rem 0.5rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &.isFocused {
    transform: translateY(-$heightInput + 19px) scale(0.9);
    background-color: white;
    color: var(--color-alpha-light);
  }
}

.input-error-msg {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: $color-gamma;
  margin: 0.5rem 1.8rem;
  line-height: 0.9rem;
}

.form-control {
  &.isError {
    label {
      color: $color-gamma;
    }

    .input {
      border-color: $color-gamma;
    }
  }
}
