.form-input {
  width: calc(50% - 1rem);
  margin: 0.5rem;

  &.isFullWidth {
    width: calc(100% - 1rem);
  }
}

.form-field {
  width: calc(50% - 1rem);
  margin: 1rem 0.5rem;

  &.fullWidth {
    width: calc(100% - 1rem);
  }
}

@media screen and (max-width: 600px) {
  .form-field {
    width: 100%;
  }
}
