.QMuiTimePicker {
  display: flex;
  flex-direction: column;
  position: relative;
  color: $color-alternate-1;

  .input-label {
    margin-left: 1.7rem;
  }

  .MuiOutlinedInput {
    &-root {
      border-radius: 25px;
      font-size: 1.5rem;
      background: white;
    }

    &-input {
      padding: 1.1rem 1.5rem 1rem 0.8rem;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-alpha);
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 6px;
  }

  .MuiIconButton-root {
    padding: 0.5rem;
  }

  .MuiInputAdornment-positionEnd {
    margin-right: 0;
    margin-left: 5px;
  }

  .MuiFormHelperText-contained {
    font-size: 1rem;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-alpha);
    border-width: 1px;
  }
}

.QMuiTimePicker.isTime {
  color: var(--color-alpha);

  .MuiOutlinedInput {
    &-root {
      width: 11rem;
    }
  }
}

.QMuiTimePicker-label {
  font-size: 1.2rem;
  color: var(--color-alpha-light);
  margin-right: 1.5rem;
}

.MuiPickersModal-dialogRoot {
  .MuiTypography {
    &-body2 {
      font-size: 1.2rem;
    }
    &-body1 {
      font-size: 1.4rem;
    }
  }

  .MuiPickersCalendarHeader-dayLabel {
    font-size: 1.2rem;
  }

  .MuiPickersDay-daySelected {
    background-color: var(--color-alpha-light);
  }

  .MuiPickersToolbar-toolbar {
    background-color: var(--color-alpha-light);
  }

  .MuiPickersToolbarText-toolbarTxt {
    font-size: 2.5rem;
  }

  .MuiPickersTimePickerToolbar-hourMinuteLabel
    .MuiPickersToolbarText-toolbarTxt {
    font-size: 4rem;
  }

  .MuiButton-root {
    font-size: 1.3rem;

    .MuiButton-label {
      color: $color-alpha !important;
    }
  }
}
