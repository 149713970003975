.Downloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $color-blue-1;
  height: 100vh;

  h1 {
    color: white;
  }

  svg {
    width: 5rem;
    height: 5rem;
  }
}

.Downloader-logo {
  width: 15rem;
  margin-bottom: 3rem;
}
