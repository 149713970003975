.Parameters {
  display: flex;
  height: 100%;
}

.Parameters-body {
  padding-left: $lay-admin-menu-width;
  flex: 1 1 auto;
  &.isCollapsed {
    padding-left: 3.5rem;
  }
}

.Parameters-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  flex-basis: $lay-admin-menu-width;
  min-width: $lay-admin-menu-width;
  background: $color-gray-1;
  box-shadow: $shadow;
  // position: relative;
  transition: 1ms margin-right ease, 200ms transform 30ms ease;
  transform: translateX(0rem);
  margin-right: 0;

  &.isCollapsed {
    transition: 1ms margin-right 220ms ease, 200ms transform ease;
    transform: translateX(calc(-#{$lay-admin-menu-width} + 3.5rem));
    margin-right: calc(-#{$lay-admin-menu-width} + 3.5rem);
  }
}

.Parameters-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: $lay-admin-menu-width;
  margin-top: 13rem;
}

.Parameters-menuItem {
  text-decoration: none;
}

.Parameters-collapseBtn {
  position: absolute;
  right: -9px;
  top: 9rem;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;

  img {
    width: 3.4rem;
  }
}

.Parameters-chronology {
  height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;

  .ChronologyEdit-container {
    flex-grow: 1;
  }
}
