/* 
 * Hidden but not for an assistive technology like a screen reader, Yahoo! method 
 */

.visually-hidden,
.sr-only {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

/*
 * Disable animations styles when reduced motion is enabled
 */

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/*
 * Change the cursor on busy elements in all browsers.
 */

[aria-busy='true'] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers.
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers.
 */

[aria-disabled='true'],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers.
 */

[aria-hidden='false'][hidden] {
  display: initial;
}

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
