.form {
  &-steps {
    &-progress {
    }
    &-content {
    }
  }
  &-step {
    &-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}
