.profile-pic {
  width: 6rem;
  height: 6rem;
  margin: 0.5rem;
  object-fit: cover;
}

.card {
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: $shadow;
}

.tooltip {
  font-size: 1.3rem;
}

.badge {
  margin-left: auto;
  background: var(--color-alpha-light);
  color: white;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 1.2rem;
  font-size: 1.3rem;
}

.two-line-elipsis {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: break-spaces;
}
