$border-radius: 15px;

.SchemaItem {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 40;
  padding: 14px 10px;
  border-radius: $border-radius;
  border: 1px solid $bgMain;
  background-color: $color-gray-1;
  box-shadow: $shadow;
  transition: all 0.5s;
  &:before {
    content: '';
    background-color: orange;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    border-radius: $border-radius 0 0 $border-radius;
  }
  &.pending {
    &:before {
      background-color: $color-gray-2;
    }
  }
  &.progress {
    &:before {
      background-color: $color-beta;
    }
  }
  &.finished {
    &:before {
      background-color: $color-alternate-1;
    }
  }
  &.active {
    background-color: $white;
    margin-left: -6rem;
    width: calc(100% + 6rem);
  }
}

.SchemaItem-left {
  position: relative;
  z-index: 10;
  background-color: white;
  padding: 6px;
  border-radius: 10px;
  margin-left: 10px;
  box-shadow: $shadow;
}

.SchemaItem-right {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin-left: auto;

  img {
    width: 20px;
    height: 15px;
  }
}

.SchemaItem-content {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 70%;
}

.SchemaItem-title {
  font-size: 1.6rem;
  margin-bottom: 0.2rem;
  font-family: CoreSansC65;
}

.SchemaItem-subtitle {
  font-size: 1.3rem;
  color: lightgray;
}

.SchemaItem-title,
.SchemaItem-subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
