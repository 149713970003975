.QTable {
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  position: relative;

  table {
    margin: auto;
    color: var(--color-alpha);
    max-width: auto;
    width: 100%;
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    position: relative;
  }

  tbody {
    &::before {
      box-shadow: $shadow;
      border-radius: 2rem;
      position: absolute;
      content: '';
      top: 6.5rem;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    background-color: white;
    border-radius: 2rem;
    font-size: 1.4rem;

    tr:not(:last-child) td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    min-height: 30rem;
  }

  th {
    height: 6.5rem;
  }

  tr:first-child {
    td:first-child {
      border-top-left-radius: 2rem;
    }

    td:last-child {
      border-top-right-radius: 2rem;
    }
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 2rem;
    }

    td:last-child {
      border-bottom-right-radius: 2rem;
    }
  }
  &.document-table-request {
    table {
      border-collapse: collapse;
    }
    tbody {
      box-shadow: 0 0 0 2px $color-beta;
      border-radius: 2rem;
      tr {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.QTable-container {
  padding: 1rem;
  padding-bottom: 2rem;
}

.QTable-cell {
  margin: 1rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.QTable-loading {
  box-shadow: $shadow;
  background-color: white;
  border-radius: 2rem;
  min-height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--color-alpha);
  font-weight: bold;

  span {
    position: absolute;
  }
}

.QTable-sortIcon {
  margin-left: 0.5rem;
  svg {
    font-size: 2rem;
  }
}

.QTable-folders {
  @include respond-to('small') {
    table {
      thead {
        tr {
          th {
            display: none;
          }
          > :nth-child(3) {
            display: table-cell;
          }
        }
      }
      tbody {
        tr {
          td {
            display: none;
          }
          > :nth-child(3) {
            display: table-cell;
          }
        }
      }
    }
  }
}
.QTable-documents {
  @include respond-to('small') {
    table {
      thead {
        tr {
          > :nth-child(2) {
            display: none;
          }
        }
      }
      tbody {
        tr {
          > :nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
