/*!
* KNACSS Reborn: Just keep it simple!
* @author: Alsacreations
* v1.0.0 2020/11
* Licence WTFPL http://www.wtfpl.net/
*/

// WARNING : Only Core and Utilities are imported by default. Just add other files if you need them.

// CORE
@import 'abstracts/variables-sass';
@import 'abstracts/mixins-sass';

@import 'base/reset-base';
@import 'base/reset-accessibility';
@import 'base/reset-forms';
@import 'base/reset-print';
@import 'base/layout';

// UTILITY CLASSES
@import 'utils/utils-global';
@import 'utils/utils-spacers';
// @import "utils/grillade";

// COMPONENTS (add them only if you need)
// @import "components/button";
@import 'components/burger';
// @import "components/checkbox";
// @import "components/radio";
// @import "components/select";
// @import "components/quote";
