$local-border-radius: 15px;

.ChronologyEdit-container {
  position: relative;
  display: flex;
  padding: 0 2rem;

  & > button:first-child {
    position: absolute;
    top: -3em;
  }
}

.ChronologyEdit-head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-right: 15px;

  & > button {
    margin-left: 10px;
  }

  .MuiFormControl-root {
    margin: 0 8px;
    width: 300px;
    margin-bottom: 1rem;
  }
}

.ChronologyEdit-left {
  width: 50%;
  position: relative;
}

.ChronologyEdit-leftHeader {
  display: flex;
  align-items: center;
  height: 100px; // CONSTANT JS
}

.ChronologyEdit-right {
  width: 50%;
  border-radius: $local-border-radius;
  padding: 5px 20px;
  box-shadow: $shadow;
  position: relative;
  border: 1px solid $bgMain;
  margin-left: 1.5rem;

  &.isEditing {
    background-color: $bgMain;
  }

  &.isCreating {
    border-top-left-radius: 0;
  }

  .QMuiTimePicker:first-child {
    max-width: 15rem;
  }
}

.ChronologyEdit-newStep {
  position: absolute;
  border-radius: 10px 0 0 10px;
  background-color: $bgMain;
  height: 100px;
  box-shadow: $shadow;
  left: 50px;
  right: 0;
}

.ChronologyEdit-actions {
  z-index: 50;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChronologyEdit-formContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Mui-disabled {
    cursor: default !important;
    filter: none;
    color: inherit;
    opacity: 1;
  }
}

.ChronologyEdit-rightHeader {
  margin-top: 10px;
  width: 100%;
}

.ChronologyEdit-infoFormContainer {
  padding: 10px 0px;
  padding-top: 0;
  display: flex;
  align-items: center;
}

.ChronologyEdit-icon {
  padding: 5px;
  font-size: 2.8em;
  line-height: 0.5;
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
  color: color('primary');
  box-shadow: $shadow;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;

  img {
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
}

.ChronologyEdit-infoForm {
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: $local-border-radius;
  width: 100%;
}

.ChronologyEdit-input {
  border-radius: $local-border-radius;
  padding: 2px 8px;
  font-size: 1.5rem;

  &:first-child {
    font-family: CoreSansC65;
    margin-right: 7rem;
    font-size: 2rem;
  }
  [name='title'] {
    color: var(--color-alpha);
  }
}

.ChronologyEdit-textArea {
  background-color: white;
  width: 100%;
  border-radius: $local-border-radius;
  font-family: inherit;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
}

.ChronologyEdit-content,
.ChronologyEdit-notes-content {
  overflow-y: scroll;
}

.ChronologyEdit-content {
  max-height: 9rem;
}

.ChronologyEdit-notes-content {
  max-height: 13rem;
}

.ChronologyEdit-backBtn {
  position: absolute;
  top: 0;
  left: 0;
}

.ChronologyEdit-right .QMuiButton {
  color: white !important;
  font-weight: 700;
  padding: 9px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 50px;
  font-size: 1.5rem !important;
  font-size: inherit;

  &.QMuiButton {
    display: flex;
    align-items: center;
  }

  &.ongoing {
    background-color: $color-beta;
  }
  &.to_validate {
    background-color: $color-beta;
  }
  &.done {
    background-color: $color-alternate-1;
  }
}

.ChronologyEdit-dates {
  .QMuiTimePicker,
  .QMuiTimePicker {
    margin: 0.8rem 0;
  }
}

.ChronologyEdit-notes {
  & > span {
    color: var(--color-alpha);
    font-weight: bold;
    font-size: 1.7rem;
  }
}

@include respond-to('small') {
  .ChronologyEdit-input:first-child {
    margin: 0;
  }

  .ChronologyEdit-right .QMuiButton {
    padding: 1rem;
    font-size: 1.2rem;
  }
  .ChronologyEdit-leftHeader {
    height: 50px;
  }
}

@include respond-to('small-up') {
  .ChronologyEdit-left {
    overflow-y: scroll;
  }
}

@media screen and (max-width: 900px) {
  .ChronologyEdit-head .FolderChronology-subdomain {
    margin: 0;
    width: auto;
  }

  .ChronologyEdit-left {
    flex-shrink: 0;
    width: 100%;
  }

  .ChronologyEdit-right {
    flex-shrink: 0;
    width: 100%;
    padding-top: 2.5rem;
  }
}
