.NoteCategoryInput {
  max-width: 600px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: $shadow;

  h4 {
    font-size: 1.8rem;
    font-weight: 600;
    color: $color-gray-2;
    margin: 0;
  }

  .QMuiButton {
    padding: 0;
    min-width: 0;
  }
}

.NoteCategoryInput-addSection {
  position: relative;
}

.NoteCategoryInput-addInput {
  color: rgb(104, 104, 104);
  background-color: rgb(219, 219, 219);
  border: 0;
  font-size: 1.5rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 3rem;
}

.NoteCategoryInput-colorPicker {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.NoteCategoryInput-icon {
  cursor: pointer;
  height: 15px;
  width: 20px;
}
