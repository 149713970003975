/*
 * component nav "burger" button
 */
/*
 * HTML template example:
 * <button class="burger-button" type="button" role="button" aria-label="open/close navigation"><i></i></button>
 * activate it with a JS toggle-class to .is-active
 * see doc : https: //www.knacss.com/doc.html#buttons
 */

/*
 * burger variables (you can change them)
 */

$burger-color: #333;
$burger-background: transparent;
$burger-hover-background: transparent;
$burger-size: 2.6rem;
$burger-weight: 5px; // size of stripes
$burger-padding: 0;

/*
 * burger button styles
 */

.burger-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.burger-button > * {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: $burger-size;
  width: $burger-size;
  padding: $burger-padding;
  background-color: $burger-background;
  background-image: linear-gradient($burger-color, $burger-color);
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100% $burger-weight;
  transition: 0.25s;
  transition-property: transform, background;
  will-change: transform, background;
}

.burger-button > *::before,
.burger-button > *::after {
  content: '';
  height: $burger-weight;
  background: $burger-color;
  transition: 0.25s;
  transition-property: transform, top;
  will-change: transform, top;
}

.burger-button:hover > * {
  background-color: $burger-hover-background;
}

.burger-button:focus {
  outline: 0;
}

.burger-button.is-active > * {
  background-image: none;
  justify-content: center;
}

.burger-button.is-active > *::before {
  transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
}

.burger-button.is-active > *::after {
  transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
}
