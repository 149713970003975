.QFile {
  display: flex;
  align-items: center;
  margin: 0.5rem;

  input {
    display: none;
  }
}

.QFile-clearBtn {
  border: 0;
  background: none;
  cursor: pointer;
  height: 24px;
  margin-top: 2px;

  svg {
    fill: $color-gamma;
  }
}

.QFile-fileName {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
