.ChronoTimeline-stepNums {
  position: absolute;
  top: calc(50px - 1.5rem);
  left: -4rem;
}

.ChronoTimeline-stepNum {
  width: 3rem;
  height: 3rem;
  margin-bottom: calc(100px - 3rem);
  background: $white;
  border-radius: 50%;
  color: $color-beta;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  border: 2px solid $color-beta;

  &.pending {
    background: $color-beta;
    color: $white;
  }

  &:not(.isLastStep)::after {
    position: absolute;
    content: '';
    top: calc(2.5rem + 2px);
    left: calc(1.3rem - 2px);
    width: 4px;
    height: calc(8rem - 2px);
    background: $color-beta;
  }
}
