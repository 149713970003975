.MainMenu {
  background: $color-gray-1;
  box-shadow: $shadow;
  position: sticky;
  width: 0;
  z-index: 10;
  height: 100%;
  transition: 400ms transform ease;
}

.MainMenu-static {
  min-height: 100%;
}

.MainMenu-static {
  position: fixed;
  height: 100vh;
  min-height: 100%;
  transition: 0.3s width ease;
  padding-top: calc(1rem + var(--lay-header-height));
  border-right: 1px solid lightgray;
  background: $color-gray-1;
}

.MainMenu-item {
  border-bottom: 0;
  height: auto;

  .Menu-link {
    flex-direction: column;
    justify-content: flex-end;
    border: none;
  }

  &.isActive,
  &:hover {
    background: none;

    .Menu-link {
      color: $color-beta;
    }

    .Menu-itemIcon {
      svg {
        &.folder,
        &.accounting {
          path {
            fill: $color-beta;
            stroke: $color-beta;
          }
        }
        &.message {
          g {
            stroke: $color-beta;
          }
        }
        &.accounting {
          circle {
            stroke: $color-beta;
          }
        }
        &.lawyer {
          g {
            fill: $color-beta;
          }
        }
      }
    }
  }

  .Menu-itemIcon {
    width: 5rem;
    margin-right: 0;
    margin-bottom: 0.1rem;
    transform: scale(2.5);
  }
}

@include respond-to('small') {
  .MainMenu-static {
    border-radius: 1.5rem;
    box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.2);
    height: 5.5rem;
    min-height: auto;
    padding: 0.5rem;
    bottom: 2rem;
    left: 1rem;
    right: 1rem;

    & > nav > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .Menu-link {
      font-size: 1.1rem;
    }
  }
}

@include respond-to('small-up') {
  .MainMenu-static {
    top: 0;
    width: 12rem;
  }

  .MainMenu-item {
    .Menu-link {
      padding: 1.5rem 0.5rem 0 0.5rem;
    }

    &:not(:last-child)::after {
      display: block;
      margin: auto;
      content: '';
      width: 50%;
      border-bottom: 1px solid var(--color-alpha-light);
      margin-top: 1.5rem;
    }

    .Menu-itemIcon {
      margin-bottom: 0.5rem;
    }
  }
}

@include respond-to('small') {
  .MainMenu {
    z-index: 50;
  }

  .MainMenu-item {
    .Menu-itemIcon {
      height: 2.5rem;
      transform: scale(1.5);
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }
}
