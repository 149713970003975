.QMuiSwitch {
  .Mui-checked .MuiIconButton-label {
    color: var(--color-alpha-light);
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: var(--color-alpha-light);
  }

  .Mui-checked:hover {
    background-color: rgba(var(--color-alpha-light), 0.9);
  }
}
