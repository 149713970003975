.documents {
  &-list {
    box-shadow: $shadow;
    border-radius: $radius-large;
    li {
      padding: map-get($spacers, '4');
      border-bottom: 1px solid $color-gray-1;
    }
    .document {
      &-title {
        color: var(--color-alpha);
      }
      &-description {
        color: $color-gray-3;
      }
    }
  }
}
